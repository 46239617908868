<template>
  <v-layout align-center>
    <v-layout align-center shrink>
      <v-switch
        v-model="internalValue"
        class="zg-text-control mb-1 mt-0"
        color="expressive_green"
        :label="label"
        :hide-details="true"
        :ripple="false"
        inset
      ></v-switch>
      <InfoButton v-if="info" :text="info"></InfoButton>
    </v-layout>
    <AutosaveIcon class="mx-4" v-if="autosave" :value="status"></AutosaveIcon>
  </v-layout>
</template>

<script>
import ModificationMixin from "@/mixins/ModificationMixin";

export default {
  mixins: [ModificationMixin],
  props: {
    value: {},
    label: {
      type: String,
      default: "",
    },
    info: {
      type: String,
      default: "",
    },
  },
  computed: {
    internalValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      },
    },
  },
  components: {
    InfoButton: () => import("@/components/buttons/InfoButton"),
    AutosaveIcon: () => import("@/components/AutosaveIcon.vue"),
  },
  mounted() {
    if (this.autosave) {
      this.startWatching("internalValue");
    }
  },
};
</script>

<style scoped>
.zg-text-control::v-deep label {
  color: rgba(0, 0, 0, 0.87);
}
</style>